jQuery(document).ready(function($){
    // $(".owl-carousel").owlCarousel();
    $(".headerlight-carousel").owlCarousel({
        loop: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
            0 : {
                items : 1
            },
            480 : {
                items : 2
            },
            768 : {
                items : 3
            }
        }
    });
    $(".team-carousel").owlCarousel({
        loop: true,
        dots: false,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
        margin: 20,
        autoplay: true,
        autoplayTimeout: 10000,
        responsive: {
            0 : {
                items : 1
            },
            480 : {
                items : 2
            },
            768 : {
                items : 3
            }
        }
    });
    $(".pro-services-carousel").owlCarousel({
        loop: true,
        dots: false,
        margin: 20,
        autoplay: true,
        autoplayTimeout: 10000,
        responsive: {
            0 : {
                items : 1
            },
            480 : {
                items : 2
            },
            768 : {
                items : 3
            }
        }
    });
});